<script>
import { pattern } from '@shared/config/vuelidate';
import { toBase64 } from '@shared/services/Utils';
import BaseColorField from '@app/components/BaseColorField.vue';
import StoreSocialLinkForm from '@app/components/StoreSocialLinkForm.vue';

export default {
  components: {
    BaseColorField,
    StoreSocialLinkForm,
  },
  i18n: {
    messages: {
      fr: {
        color_infos: {
          brand_color: `
            Cette couleur s'applique aux menus, ainsi qu'à tous les autres éléments
            personnalisables si vous ne les avez pas spécifiés individuellement
            (liens, boutons, bordures...)
          `,
          background_color: "Cette couleur s'applique aux arrières plans personnalisables (menu apprenant, bannière)",
          heading_color: "Cette couleur s'applique aux titres principaux des pages",
          text_color: "Cette couleur s'applique aux textes",
          button_color: "Cette couleur s'applique aux boutons et liens",
          button_text_color: "Cette couleur s'applique au texte des boutons",
        },
      },
    },
  },
  head: {
    title: 'Image de marque',
  },
  data() {
    return {
      isLoading: false,
      store: {
        logo: '',
        favicon: '',
        brand_color: '',
        background_color: '',
        heading_color: '',
        text_color: '',
        button_color: '',
        button_text_color: '',
      },
    };
  },
  validations: {
    store: {
      brand_color: {
        pattern: pattern(/^#[0-9A-Fa-f]{6}$/),
      },
      background_color: {
        pattern: pattern(/^#[0-9A-Fa-f]{6}$/),
      },
      heading_color: {
        pattern: pattern(/^#[0-9A-Fa-f]{6}$/),
      },
      text_color: {
        pattern: pattern(/^#[0-9A-Fa-f]{6}$/),
      },
      button_color: {
        pattern: pattern(/^#[0-9A-Fa-f]{6}$/),
      },
      button_text_color: {
        pattern: pattern(/^#[0-9A-Fa-f]{6}$/),
      },
    },
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));

    this.colors = {
      brand_color: 'Couleur principale',
      background_color: 'Couleur d\'arrière plan',
      heading_color: 'Couleur des titres',
      text_color: 'Couleur des textes',
      button_color: 'Couleur des boutons',
      button_text_color: 'Couleur du texte des boutons',
    };
  },
  methods: {
    handleUpload(key, file) {
      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form1 });
      toBase64(file)
        .then((base64) => (this.store[key] = base64))
        .finally(() => loader.close());
    },
    handle() {
      const colors = Object.keys(this.colors)
        .reduce((acc, curr) => {
          acc[curr] = this.store[curr];
          return acc;
        }, {});
      const store = {
        ...this.store,
        logo: this.store.logo,
        favicon: this.store.favicon,
        ...colors,
      };

      if (store.logo && store.logo.startsWith('http')) {
        delete store.logo;
      }

      if (store.favicon && store.favicon.startsWith('http')) {
        delete store.favicon;
      }

      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', store)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Créez votre image de marque
      </h2>
      <p>
        Pour se différencier des autres formations en ligne,
        il faut être identifiable et reconnaissable.
      </p>
      <p>
        Teachizy vous permet de choisir <strong>votre logo et vos couleurs</strong>.
      </p>
      <p>
        Vous pouvez uniquement configurer la couleur principale.
        Mais pour une configuration plus fine,
        veuillez configurer toutes les autres couleurs.
      </p>
      <p class="has-text-weight-bold">
        Ces éléments apparaîtront sur votre page d'accueil
        accessible en
        <a :href="$store.getters['auth/url']" target="_blank">cliquant ici</a>.
      </p>
    </div>

    <div class="column is-8 is-relative">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-field label="Logo (1024x1024 max)" :addons="false">
              <template v-if="store.logo">
                <div class="pt-2 has-text-centered has-background-light bradius-8">
                  <img :src="store.logo" class="ofit-contain local-h-96 bradius-8">
                </div>
                <b-button
                  type="is-text"
                  size="is-small"
                  class="has-text-danger has-text-centered w-full"
                  @click.stop="store.logo = ''">
                  Supprimer
                </b-button>
              </template>
              <b-upload v-else drag-drop expanded @input="handleUpload('logo', $event)">
                <div class="p-5 content has-text-centered">
                  <p>
                    <b-icon icon="camera" />
                  </p>
                  <p>Cliquez ou glissez votre image</p>
                </div>
              </b-upload>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field label="Favicon (64x64 max, en PNG)" :addons="false">
              <template v-if="store.favicon">
                <div class="pt-2 has-text-centered has-background-light bradius-8">
                  <img :src="store.favicon" class="ofit-contain local-h-96 bradius-8">
                </div>
                <b-button
                  type="is-text"
                  size="is-small"
                  class="has-text-danger has-text-centered w-full"
                  @click.stop="store.favicon = ''">
                  supprimer
                </b-button>
              </template>
              <b-upload
                v-else
                drag-drop
                expanded
                accept="image/png"
                @input="handleUpload('favicon', $event)">
                <div class="p-5 content has-text-centered">
                  <p>
                    <b-icon icon="camera" />
                  </p>
                  <p>Cliquez ou glissez votre image</p>
                </div>
              </b-upload>
            </b-field>
          </div>
          <div v-for="(label, color) in colors" :key="color" class="column is-6">
            <b-field v-bind="$getErrorProps($v.store[color], ['pattern'])">
              <template #label>
                <span class="is-flex is-justify-content-space-between">
                  {{ label }}
                  <b-tooltip position="is-left" multilined>
                    <template #content>
                      <span v-t="`color_infos.${color}`" />
                    </template>
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </span>
              </template>
              <BaseColorField v-model="store[color]" />
            </b-field>
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-12">
      <StoreSocialLinkForm />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.local {
  &-h-96 {
    height: 96px;
  }
}
</style>
