<script>
import { required, url } from 'vuelidate/lib/validators';
import linkTypes from '@shared/assets/data/social_links.json';
import APIStore from '@app/services/API/Store';

export default {
  data() {
    return {
      isLoading: false,
      linkTypes,
      link: {
        name: 'Site Web',
        url: '',
      },
      links: [],
    };
  },
  validations: {
    link: {
      url: {
        required,
        url,
      },
    },
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  mounted() {
    const loader = this.$buefy.loading.open({ container: this.$refs.form });
    APIStore.getSocialLinks(this.authStore.uuid)
      .then((data) => (this.links = data.data))
      .finally(() => loader.close());
  },
  methods: {
    handleSocialLink() {
      this.isLoading = true;
      APIStore.addSocialLink(this.authStore.uuid, { ...this.link })
        .then((data) => {
          this.link.url = '';
          this.links.push(data.data);
          this.$showMessage.success();
          this.$v.$reset();
        })
        .finally(() => (this.isLoading = false));
    },
    deleteLink(one) {
      const loader = this.$buefy.loading.open({ container: this.$refs.form });
      APIStore.deleteSocialLink(this.authStore.uuid, one.id)
        .then(() => (this.links = this.links.filter((link) => link.id !== one.id)))
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Vos liens sociaux
      </h2>
      <p>
        Ajoutez des liens vers un site externe, votre page Facebook, Twitter, Instagram
        ou n'importe quelle autre page qui aidera vos apprenants à en savoir plus sur vous.
      </p>
      <p class="has-text-weight-bold">
        Ces liens apparaîtront sur votre page d'accueil
        accessible en
        <a :href="$store.getters['auth/url']" target="_blank">cliquant ici</a>.
      </p>
    </div>

    <div ref="form" class="column is-8 is-relative">
      <div class="box">
        <div class="columns is-multiline">
          <form novalidate class="column" @submit.prevent="dataIsValid(isLoading) && handleSocialLink()">
            <b-field
              label="Lien vers une page web"
              v-bind="$getErrorProps($v.link.url, ['required', 'url'])"
            >
              <div class="field has-addons">
                <b-select v-model="link.name">
                  <option v-for="(icon, name) in linkTypes" :key="name" :value="name">
                    {{ name }}
                  </option>
                </b-select>
                <b-input
                  v-model="link.url"
                  placeholder="Entrez votre lien (https://monsite.fr)"
                  :icon="linkTypes[link.name]"
                  :icon-pack="linkTypes[link.name] == 'globe' ? 'fa' : 'fab'"
                  expanded
                  required
                />
                <p class="control">
                  <b-button type="is-primary" native-type="submit">
                    Ajouter
                  </b-button>
                </p>
              </div>
            </b-field>
          </form>
        </div>

        <hr v-if="links.length">

        <div v-if="links.length" class="columns is-multiline">
          <div v-for="one in links" :key="one.id" class="column is-12">
            <b-field :label="one.name">
              <div class="field has-addons">
                <b-input
                  v-model="one.url"
                  :title="one.url"
                  :icon="linkTypes[one.name]"
                  :icon-pack="linkTypes[one.name] == 'globe' ? 'fa' : 'fab'"
                  expanded
                  disabled
                />
                <p class="control">
                  <a
                    class="button is-light"
                    :href="one.url"
                    target="_blank"
                    title="Suivre le lien"
                    rel="noopener noreferrer"
                  >
                    <b-icon type="is-primary" icon="link" />
                  </a>
                </p>
                <p class="control">
                  <b-button
                    title="supprimer"
                    type="is-danger"
                    icon-left="trash"
                    @click="deleteLink(one)"
                  />
                </p>
              </div>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
